<template>
    <div class="builder-screen">
        <bh-loading :show="loading" />
        <div style="width: 100vw; height: 100vh" class="dF fC">
            <TopBarTemplate
                :size="frameSize"
                :mode="previewMode? 'preview' : 'none'"
                @close="exit"
                @save="save"
                @preview="preview"
            />

            <div class="w-full dF f1 bg-white">
                <div class="f1 relative builder-screen-wrapper">
                    <div
                        class="iframe-holder bg-white"
                        :style="iframeStyle"
                        :class="
                        frameSize.current === 'mobile'
                            ? 'phone phone-iframe-container'
                            : ''
                        "
                    >
                        <iframe class="builder-iframe" src="" style="transform: scale(0.65); transform-origin: 0 0; width: 154%; height: 153%;" />
                    </div>
                </div>
                <SideToolbar
                    :event="windowMsg"
                    :project="project"
                    :page="currentPage"
                    @page-change="() => {}"
                    :selected-section="selectedSection"
                    :hide="previewMode"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { engine } from "@/assets/engine";
import { sections } from "@/assets/sections";
import { formatNumber, validateEmail, setProp } from "bh-mod";
import SideToolbar from "@/components/common/SideToolbar";
import bhLoading from "bh-mod/components/common/Loading";
import TopBarTemplate from "@/components/site/TopBarTemplate";
import axios from "axios";
let scrollTop = 0;
const modelStyles = {
  color1: null,
  hover1: null,
  color2: null,
  hover2: null,
  color3: null,
  hover3: null,
  column: null,
  imgEffect: null,
};
let tld = window.location.href.includes('.com')? 'com' : 'dev'
function dataURItoBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    //Old Code
    //write the ArrayBuffer to a blob, and you're done
    //var bb = new BlobBuilder();
    //bb.append(ab);
    //return bb.getBlob(mimeString);

    //New Code
    return new Blob([ab], {type: mimeString});


}
const processProject = project => {

    const processItem = (item,data) => {
        if (item.hasOwnProperty('default') && item.id){
            if (!data.hasOwnProperty(item.id)){
                data[item.id] = item.default
            }
        } else if (item.children && item.children.length){
            item.children.forEach(childItem => processItem(childItem,data))
        }
    }

    if (project.settings && project.settings.forEach){
        if (!project.data) project.data = {}
        project.settings.forEach(item => processItem(item,project.data))
    }

    if (project.pages && project.pages.length){

        project.pages.forEach(page => {
            if (page.sections && page.sections.length){
                page.sections.forEach(sec => {
                    if (sec.settings && sec.settings.forEach){
                        sec.settings.forEach(item => processItem(item,sec.data))
                    }
                })
            }
        })
    }

    return project
}

export default {
    components: {
        bhLoading,
        TopBarTemplate,
        SideToolbar,
    },
    data() {
        return {
            previewMode:false,
            selectedSection: "",
            frameLoaded: false,
            sections,
            project: {},
            windowMsg: {},
            buttonTool: {
                show: false,
                configure: {},
            },
            frameSize: {
                current: "desktop",
            },
            linkTool: {
                show: false,
                configure: {
                link: "",
                linkClass: "",
                },
            },
            SRC: "",
            recentColorLookup2: {},
            recentColors: [],
            colorPicker: {
                show: false,
                callback: null,
                path: [],
            },

            widgetSetting: {
                show: false,
                type: "",
                configure: {},
            },
            widgetStyle: {
                show: false,
                type: "",
                configure: {
                bg: "#fff",
                titleColor: "#F00",
                titleHover: "#FF0",
                textColor: "#00F",
                textHover: "#F0F",
                },
            },
            navMenus: {},
            pages: [],
            IFRAME: null,
            previewing: false,
            previewFields: [],
            leadScoreShow: false,
            customFields: {},
            showAddCustomField: false,
            deleting: false,
            deletePopup: false,
            loading: false,
            template: {
                name: "",
                url: "",
                instance: "",
                published: false,
            },
            tags: [],
            exitAfter:false
        };
    },
    watch: {
        previewMode(val){
            this.setMode(val)
        },
        "frameSize.current"(val) {
            console.log("VALLLL CHANGED SIZE", val);
        },
        "project.fonts": {
            deep: true,
            handler(val) {
                this.setTimeoutToUpdate()
            },
        },
        "project.colors": {
            deep: true,
            handler(val) {

                this.setTimeoutToUpdate()

            },
        },
        "project.seo": {
            deep: true,
            handler(val) {
                this.setTimeoutToUpdate()

            },
        },
        "project.data": {
            deep: true,
            handler(val) {
                this.setTimeoutToUpdate()
            },
        },
        "project.pages": {
            deep: true,
            handler(val) {
                this.setTimeoutToUpdate()
            },
        },
        currentPage: {
            deep: true,
            handler(val) {
                console.log('CURRENT PAGE', val)
            },
        },
        activeKey(val) {
            console.log("CHANGING PACKAGE", val);
            this.openNewFloor = val.key + 0;
        },
        fields(val) {
            if (val.length) {
                this.refreshFields(val);
            }
        },
    },
    computed: {
        tempEmail() {
            return this.$store.state.appData.tempEmail;
        },
        currentPage() {
            if (this.project && this.project.pages && this.project.pages.length){

                let currentSection = this.$store.state.appData.builder.currentSection
                console.log('CURRENT SECTION', currentSection)
                let currPageIndex = this.project.pages.findIndex(page => {
                    if (!page.sections.length) console.log('PAGE SECTIONS NONEEEEEEE', page)
                    return page.sections[0].id === currentSection
                })
                if (currPageIndex > -1) return this.project.pages[currPageIndex];
                return this.project.pages[0];
            } else return {};
        },
        iframeStyle() {
            return {
                // width:this.frameSize.current === 'mobile'? '456px' : '100%',
                // height:this.frameSize.current === 'mobile'? '700px' : '100%'
            };
        },
        cson() {
            return this.$store.state.appData.cson;
        },
        siteURL() {
            return this.$store.state.appData.siteURL;
        },
        viewPortWidth() {
            return this.$store.state.appData.dom.w;
        },
        recentColorLookup() {
            let type = this.widgetStyle.type;
            if (!type || !this.widgetStyle.show) return [];

            let lookup = {
                bg1: ["all"],
                color1: ["models", "navigation", "md-fp-details-1"],
                hover1: ["models", "navigation", "md-fp-details-1"],
                bg2: ["md-fp-details-1"],
                color2: ["models", "navigation", "md-fp-details-1"],
                hover2: ["models"],
                color3: ["models", "navigation"],
                hover3: ["models"],
            };

            let colorsEntries = Object.entries(lookup)
                .filter(([key, value]) => {
                return value.includes("all") || value.includes(type);
                })
                .map((x) => x[0]);

            let result = [];

            colorsEntries.forEach((clr) => {
                let color = this.widgetStyle.configure[clr];
                if (!result.includes(color)) result.push(color);
            });

            console.log("RESULTTTTTTTTTTTTTT", result);
            return result;
        },
        modelTags() {
            return this.tags.filter((x) => x.type === "models");
        },
        lotTags() {
            return this.tags.filter((x) => x.type === "lots");
        },
        instance() {
            return this.$store.state.instance;
        },
        dialog() {
            return this.$store.state.appData.templateDrawer;
        },
        pageReq() {
            return this.$store.state.appData.builder.currentPageSlug
        },
        pageTop() {
            return this.$store.state.appData.builder.top;
        },
    },
    methods: {
        setMode(preview = false){

            console.log('SETTING MODe, IS PREVIEW ???', preview)

            let urlParam = `updated=${Date.now()}`
            let obj = {
                datas:{_modelsData:this.modelsData, _siteplanData:this.siteplanData,_amenitiesData:this.amenitiesData,_unitsData:this.unitsData},
                project: this.project,
            }

            if (!preview) {

                let currentSection = this.$store.state.appData.builder.currentSection
                let slug = this.pageReq
                if (slug === this.project.slug) slug = 'page-touchstart'
                urlParam += `&builder=true&touch=1&slug=${slug}`;
                if (currentSection) urlParam += `&sec=${currentSection}`
                else if (this.pageTop) urlParam += `&top=${this.pageTop}`;

                obj.pageReq = this.pageReq

            }

            this.$store.commit('LOAD',true)
            console.log('SET MODE ')
            axios.post(`${this.siteURL}/savetouch`, obj).then( ({ data: saved }) => {

                if (saved && saved.id){
                    let url = `${this.$store.state.appData.siteURL}/tmp/${saved.id}?${urlParam}`
                    document.querySelector("iframe.builder-iframe").src = url;
                }

            }).finally( () => {
                this.$store.commit('LOAD',false)
            })
        },
        setTimeoutToUpdate(){
            const makeAPICall = () => {
                this.updateFrame();
            };

            if (this.timerId) clearTimeout(this.timerId);

            this.timerId = setTimeout(function () {
                makeAPICall();
                this.timerId = undefined;
            }, 400);
        },
        async exit(save = true){
            if (save) {
                this.exitAfter = true
                await this.save()
            }
            else {
                this.$router.push('/')
            }
        },
        async preview(){
            // if (this.previewMode) return this.previewMode = false
            // this.previewMode = true
            console.log('INSTANCE', this.instance)
            this.$store.commit("LOAD", true);

            if (!this.modelsData){
                let models = await fetch(`https://api.bildhive.${this.$tld}/v2/models?token=${this.instance.id}&children=1`).then(x => x.json())
                this.modelsData = models
                this.$store.commit('SET_PROP', {where:['builder','datas','models'],what:models})
            }


            this.$store.commit('SET_PROP', {where:['builder','currPage'],what:this.currentPage})

            if (!document.querySelector("iframe.builder-iframe")) return

            // let bildURL = `${this.ctnURL}/bildsite`
            let bildURL = `https://ctn.bildhive.${this.$tld}/bildsite`

            let { data: saved } = await axios.post(bildURL, {
                renderAll:true,
                datas:{
                    _modelsData:this.modelsData,
                    model:this.$store.getters.previewModel
                },
                iSlug:this.instance.slug,
                pageReq: this.pageReq,
                project: this.project,
            })



            if (!saved.id || !saved.url){

                return this.$message.error(
                    "There was a problem while saving Web site preview"
                );
            }

            let slug = ''

            if (this.$store.getters.currPage && !this.$store.getters.currPage.isIndex){
                slug = this.$store.getters.currPage.slug
            }

            let theURL = `${saved.url}/${slug}`;
            this.$store.commit("LOAD", false);
            window.open(theURL)
        },
        packageProject(){
            let project = JSON.parse(JSON.stringify(this.project))

            let {pages} = project
            // console.log('PAGESSSS', pages.forEach)
            pages.forEach(page => {
                // if (page.id.indexOf('add_') === 0) delete page.id
                // if (page.header && page.header.id.includes('add_')) delete page.header.id
                // if (page.footer && page.footer.id.includes('add_')) delete page.footer.id
                // if (!page.sections || !page.sections.length) return
                console.log('PAGE\ SECTIONSSS', page.sections.forEach)
                page.sections = page.sections.map( (sec,secI) => {
                        let {id,...newSec} = sec
                        if (sec.id.includes('add_')) return newSec
                        return sec
                })
                // pages.sections.forEach((section,idx) => {

                // })
            })
            return project
        },
        handleUpload(fileObj){
            return new Promise( (resolve,reject) => {
                const formData = new FormData();
                formData.append('files', fileObj, `${this.project.id}.jpg`);
				formData.append('force', 1);

                this.$api.post('/upload',formData,{headers: { 'Content-Type': 'multipart/form-data' }}).then( ({data}) => {
                    if (Array.isArray(data) && data[0]){
                        resolve(data[0].url)
                    } else {
                        reject()
                    }
                }).catch( err => {
                    this.$message.error(this.$err(err))
                    reject()
                })
            })

        },
        async continueSaving(img){
            let imgUrl = ''
            try {
                let file = dataURItoBlob(img)
                imgUrl = await this.handleUpload(file)
            } catch(err){
            }

            if (imgUrl){
                this.pkgProject.image = imgUrl
            }
            const formData = new FormData()
            await this.$api.put(`/projects/:instance/${this.project.id}`, this.pkgProject).catch(err => {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err))
				}
			})
            this.$message.success('Project Saved')
            this.$store.commit('UPDATE_PROJECT', this.pkgProject)
            this.$store.commit('LOAD',false)

            if (this.exitAfter){
                this.exitAfter = false
                this.$router.push('/')
            }
        },
        async save() {
            this.$store.commit('LOAD',true)
            this.pkgProject = this.packageProject()
            document.querySelector("iframe").contentWindow.postMessage({
                action: "screenshot",
                for:'save',
            },this.siteURL);

        },
        async updateFrameSize() {
            if (this.frameLoaded && document.querySelector("iframe")) {
                let box = document.querySelector("iframe").getBoundingClientRect();
                let { width, height, left, right, top, bottom } = box;
                document
                .querySelector("iframe")
                .contentWindow.postMessage({
                    action: "setIframe",
                    value: { width, height, left, right, top, bottom },
                },this.siteURL);
            }
        },
        async updateFrame() {
            this.$store.commit("LOAD", true);

            // if (!this.amenitiesData){
            //     console.log('AMENITIES DATA NOT FOUND')
            //     this.amenitiesData = await fetch(`https://api.bildhive.${tld}/v1/amenities?token=${this.instance.id}`).then(x => x.json())
            //     this.$store.commit('SET_PROP', {where:['datas','amenitiesData'],what:this.amenitiesData})
            // }

            // if (!this.siteplanData){
            //     this.siteplanData = await fetch(`https://api.bildhive.${tld}/v1/siteplan?token=${this.instance.id}`).then(x => x.json())
            //     this.$store.commit('SET_PROP', {where:['datas','siteplanData'],what:this.siteplanData})
            // }
            // if (!this.modelsData){
            //     //
            //     let models = {}

            //     if (this.siteplanData.unitGroups) models = {unitGroups:this.siteplanData.unitGroups}
            //     else models = await fetch(`https://api.bildhive.${tld}/v1/models?token=${this.instance.id}`).then(x => x.json())
            //     if (!models || !models.unitGroups) models = {unitGroups:[]}

            //     this.modelsData = models
            //     this.$store.commit('SET_PROP', {where:['datas','models'],what:models})
            // }
            // if (!this.featuresData){
            //     this.featuresData = await fetch(`https://api.bildhive.${tld}/v1/features?token=${this.instance.id}`).then(x => x.json())
            //     this.$store.commit('SET_PROP', {where:['datas','featuresData'],what:this.featuresData})
            // }


            // this.$store.commit('SET_PROP', {where:['builder','currPage'],what:this.currentPage})

            if (!document.querySelector("iframe.builder-iframe")) return;

            if (this.frameLoaded) {
                console.log("FRAME LOADED SOOOOOOO setting TOP");
                document
                .querySelector("iframe")
                .contentWindow.postMessage({ action: "setTop" }, this.siteURL);
                this.frameLoaded = false;
            }

            console.log('UPDATING FRAME')
			axios.post(`${this.siteURL}/savetouch`, {
                pageReq: this.pageReq,
                project: this.project,
            }).then( ({ data: saved }) => {

				if (!saved.id){
					this.$store.commit("LOAD", false)
					return this.$message.error(
						"There was a problem while saving landing page preview"
					);
				}

				let currentSection = this.$store.state.appData.builder.currentSection
	            let slug = this.pageReq
	            if (slug === this.project.slug) slug = 'page-touchstart'
	            let frameSrc = `${this.siteURL}/tmp/${saved.id}?updated=${Date.now()}&builder=true&touch=1&slug=${slug}`;
	            if (currentSection) frameSrc += `&sec=${currentSection}`
	            else if (this.pageTop) frameSrc += `&top=${this.pageTop}`;

				document.querySelector("iframe.builder-iframe").src = frameSrc;
			}).finally( () => {
				this.$store.commit("LOAD", false)
			})
        },
        topbarAction(action) {
            if (action == "preview") {
                console.log("PREVIEW CLICKED");
            } else if (action == "edit") {
                console.log("EDIT CLICKED");
            } else if (action == "code") {
                console.log("CODE CLICKED");
            }
        },
        nextStep() {
            console.log("NEXT STEPPPP");
            this.continue();
        },
        resetColor() {
            this.colorPicker = {
                show: false,
                path: [],
                callback: null,
            };
        },
        colorCallBack(color) {
            setProp(this, this.colorPicker.path, color.hex);
            this.resetColor();
        },
        chooseColor(path) {
            this.colorPicker = {
                show: true,
                path,
                callback: this.colorCallBack,
            };
        },
        updateMenu(menus) {
            this.navMenus = menus;
        },
        reset() {
            this.frameSize = { current: "desktop" };
        },
        close() {
            this.reset();
            this.$store.commit("SET_PROP", {
                where: "templateDrawer",
                what: {
                show: false,
                type: "add",
                site: "",
                data: {},
                },
            });
        },
        saveAndContinue() {},
    },
    async created() {

        let project = await this.$api.get(`/projects/:instance/${this.$route.params.id}`).catch(err => {
			if (!err || !err.response || !err.response.status || err.response.status !== 400) {
				this.$message.error(this.$err(err))
			}
		})
        project = project && project.data || false

        processProject(project)

        if (!project || !project.id) return console.error("Something happend while retrieving your project")

        let indexPageSlug = ''

		if (project.pages && project.pages.length){
            console.log('PROJECT PAGES', project.pages)
			for (let i = 0; i < project.pages.length; i++){
				if (project.pages[i].sections[0].data.hasOwnProperty('instance') && !project.pages[i].sections[0].data.instance){
					project.pages[i].sections[0].data.instance = this.instance.id
				}
			}
            let indexPage = project.pages.find(x => x.isIndex)
            console.log(indexPage)
            if (indexPage && indexPage.slug) indexPageSlug = indexPage.slug
            else indexPageSlug = project.pages[0].slug
        }
        this.$store.commit('SET_PROP',{where:['builder','currProject'],what:project})

        this.$store.commit('SET_PROP', {where:['builder','currentPageSlug'],what:indexPageSlug})


        project.deletedSections = []
        project.deletedPages = []

        if (!project.includes) project.includes = {js:'',css:'',head:''}

        this.project = project;
        this.$store.commit("LOAD", false);

        window.onmessage = (e) => {
            if (
                e &&
                e.data &&
                e.data.source &&
                typeof e.data.source === "string" &&
                e.data.source.includes("vue")
            )
                return null;
            if (typeof e.data.type === "string" && e.data.type.includes("webpack"))
                return null;

            if (!e.data.action) return;

            let evt = e.data;
            this.windowMsg = evt;

            console.log("EEVVVTTT ACTION in parent => ", evt);

            if (evt.action === "frameLoaded") {

                this.frameLoaded = true;

            } else if (evt.action === "selectedWidget") {

                this.$store.commit('SET_PROP',
                    {
                        where:['builder','currentPageSlug'],
                        what:evt.slug
                    }
                )

            } else if (evt.action === "notify" && evt.value && evt.value.text && evt.value.text.trim() && typeof this.$message[evt.value.type] === 'function' ) {

                this.$message[evt.value.type](evt.value.text)

            } else if (evt.action.includes("section")) {
                if (evt.action === "sectionEdit" && evt.id) {
                this.selectedSection = evt.id;
                }
            } else if (evt.action === "appendSection" && evt.id) {
                this.prevSection = evt.id;
            } else if (evt.action === "screencaptured" && evt.for) {

                console.log('SCREEN CAPTURED', evt)
                this.continueSaving(evt.value)

            }
        }

        window.onresize = (e) => {
            this.updateFrameSize();
        }
        this.updateFrameSize()

    },
};
</script>

<style lang="scss" scoped>
    .field-box {
        border: 1px solid var(--light-gray);
    }
    [class*="field-box-"] {
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
        transition: color 0.3s ease-out;
        &:hover {
            color: var(--light-purple);
        }
    }
    .field-box-edit {
        right: 30px;
        &:hover {
            color: var(--orange);
        }
    }
    .preview-form .ant-card-head {
        background: var(--off-white-dark);
    }
    .builder-screen {
        opacity: 0;
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: 100;
        transform: translate3d(0, 50px, 0);
        animation: builder-in 0.3s ease-out;
        animation-fill-mode: forwards;
    }
    @keyframes builder-in {
        to {
            opacity: 1;
            transform: translate3d(0, 0, 0);
        }
    }
</style>


<style lang="scss">
    .widget-settings {
        [role="tab"] {
            padding-top: 0;
        }
    }
    .builder-screen-wrapper {
        background: #efefef;
        overflow: hidden;
    }
    .builder-iframe {
        box-shadow: 1px 3px 5px rgba(100, 100, 100, 0.3);
        width: 100%;
        height: 100%;
    }
    .phone {
        position: absolute;
        top: 120px;
        > iframe {
            position: relative;
            width: 269px;
            height: 480px;
            top: 50px;
            left: 14px;
            background: #fff;
            z-index: 10;
        }
    }
    .iframe-holder {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        width: 100%;
        height: 100%;
    }

    .phone-iframe-container {
        width: 300px;
        height: 594px;
        background-image: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/121761/Nexus_5_Mockup_300px.png");
        background-position: center center;
        background-repeat: no-repeat;
        z-index: 55;
        position: absolute;
    }
</style>
